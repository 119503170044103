<template>
  <div
    id="consultants-page"
  >
    <section id="consultants-hero">
      <v-responsive
        min-height="45vh"
        class="mx-auto"
      >
        <v-container class="justify-center fill-height">
          <v-row>
            <v-col
              align-self="center"
              md="7"
            >
              <vue-aos animation-class="animate__animated animate__fadeInLeft">
                <div
                  :class="[ $vuetify.breakpoint.smAndDown ? 'text-center' : 'mb-9']"
                  class="text-h3 font-weight-medium mb-9"
                  v-text="$t('Pages.Offers.offer_title')"
                />
              </vue-aos>
              <vue-aos animation-class="animate__animated animate__fadeInLeft">
                <p
                  class="leading-text text-h6 font-weight-regular mb-12"
                  v-text="$t('Pages.Offers.offer_text')"
                />
              </vue-aos>
              <vue-aos
                v-if="!isAuthenticated"
                animation-class="animate__animated animate__fadeInLeft"
              >
                <v-btn
                  outlined
                  rounded
                  class="mr-3"
                  :to="{ name: 'accounts-login' }"
                  :block="$vuetify.breakpoint.smAndDown"
                  x-large
                  v-text="$t('Pages.Buyers.register')"
                />
              </vue-aos>
            </v-col>
            <v-col
              md="5"
            >
              <vue-aos animation-class="animate__animated animate__fadeInLeft">
                <v-row justify="center">
                  <v-img
                    width="300"
                    max-width="300"
                    class="justify-center align-center"
                    src="@/assets/img/spotlight_dark_300.png"
                  />
                </v-row>
              </vue-aos>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </section>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      class="elevation-0"
      preserveAspectRatio="none"
      style="width: 1366px; height: 65px; min-width: 100%; margin-bottom: -8px;"
      viewBox="0 0 1366 65"
    >
      <path
        fill="#1a3d4f"
        d="M893.5 47.234c199.09-23.217 300.09-19.527 472 0h15.5v115H-35v-115c187.06-23.272 291.941-22.683 479 0 186.555 19.807 282.621 15.576 449.5 0z"
      />
    </svg>
    <section
      id="offers-feature"
      class="neutral py-10"
    >
      <v-responsive
        min-height="45vh"
        max-width="1200px"
        class="mx-auto"
      >
        <OffersList
          :offers="offers"
          :create-alert="createAlert"
        />
      </v-responsive>
    </section>
    <svg
      v-if="!isAuthenticated"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      preserveAspectRatio="none"
      style="width: 1366px; height: 65px; min-width: 100%;"
      viewBox="0 0 1366 65"
    >
      <path
        fill="#1a3d4f"
        d="M449 18C249.91 41.216 148.911 37.526-23 18h-15.5V-97h1416V18c-187.06 23.272-291.94 22.683-479 0C711.945-1.808 615.879 2.424 449 18z"
      />
    </svg>
    <section
      v-if="!isAuthenticated"
      id="bottom-section"
    >
      <v-responsive
        min-height="45vh"
        class="mx-auto"
      >
        <v-container class="justify-center fill-height">
          <v-row class="justify-center mb-6">
            <v-col
              md="4"
              class="mb-6"
            >
              <h3
                class="mb-4 text-center"
                v-text="$t('Pages.Offers.easy_title')"
              />
              <p
                class="text-center"
                v-text="$t('Pages.Offers.easy_text')"
              />
              <v-btn
                light
                block
                rounded
                :to="{ name: 'accounts-login' }"
                v-text="$t('Pages.Buyers.register')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueAos from 'vue-aos'
import OffersList from '@/pages/offers/OffersList.vue'
import { offers } from '@/data/offers'

export default {
  name: 'InnerOffers',
  components: { OffersList, VueAos },
  props: {
    createAlert: {
      type: Function,
      default: function () {
      }
    }
  },
  data () {
    return {
      offers
    }
  },
  computed: {
    ...mapState(['isAuthenticated'])
  },
  mounted () {
    if (this.$route.query.offer) {
      setTimeout(() => {
        this.scrollToOffer(this.$route.query.offer)
      })
    }
  },
  methods: {
    scrollToOffer (id) {
      const offerElement = document.getElementById(id)
      this.$vuetify.goTo(offerElement, { duration: 1000, offset: 20 })
    }
  },
  metaInfo () {
    return {
      title: 'Offers'
    }
  }
}
</script>
