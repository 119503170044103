export const offers = [
  {
    companyName: 'Bolagspartner',
    logoFilename: require('@/assets/img/offers/logos/fortnox.png'),
    offerCode: '',
    srcInhouse: require('@/assets/img/offers/inhouse/trygghansa.png'),
    offerUrl: 'https://www.fortnox.se/lagerbolag',
    files: [],
    sv: {
      title: 'Fortnox',
      descriptionVeryShort: 'Få org.nr inom 2 timmar',
      descriptionShort: 'Börja din resa genom att få hjälp med att starta ditt företag med Fortnox',
      descriptionLong: 'Fortnox är en ledande leverantör av programvara för småföretag i Sverige. De erbjuder lösningar för bokföring, fakturering, lönehantering och mer. Med vårt erbjudande hjälper Fortnox dig att digitalisera och effektivisera din företagsadministration!'
    },
    en: {
      title: 'Fortnox',
      descriptionVeryShort: 'Get org. number within 2 hours',
      descriptionShort: 'Start your journey by getting help to start your company with Fortnox',
      descriptionLong: 'Fortnox is a leading provider of software for small businesses in Sweden. They offer solutions for accounting, invoicing, payroll, and more. With our offer, Fortnox helps you digitize and streamline your business administration!'
    }
  },
  {
    companyName: 'Slipp Redovisning',
    logoFilename: require('@/assets/img/offers/logos/slipp.png'),
    offerCode: '',
    srcInhouse: require('@/assets/img/offers/inhouse/slipp.png'),
    offerUrl: 'https://slipp.se/partner/gigport/',
    files: [],
    sv: {
      title: 'Bokföring',
      descriptionVeryShort: 'Få en månad gratis',
      descriptionShort: 'Slipp din redovisning och överlåt det till någon som älskar att göra jobbet, digitalt dessutom',
      descriptionLong: 'Med ”Slipp Redovisning”, låter du en professionell redovisningsfirma sköta din redovisning. Du får en personlig redovisningskonsult, som hjälper dig när så behövs, men det mesta sköter du via en app där du scannar/fotar och skickar in, kvitton, skattebesked, fakturor, lönebesked mm. Allt detta till ett lågt fast pris.'
    },
    en: {
      title: 'Accounting',
      descriptionVeryShort: 'Get one month for free',
      descriptionShort: 'Focus on your business and leave the accounting to someone who loves it. ',
      descriptionLong: '"Slipp Redovisning" handles all your accounting in a digital way. You get a personal accountant but almost all communication takes place via an app. Things like tax payment, salary and expenses will be digital. All this with a fixed monthly cost.'
    }
  },
  {
    companyName: 'Nordea',
    logoFilename: require('@/assets/img/offers/logos/nordea.png'),
    offerCode: '',
    srcInhouse: require('@/assets/img/offers/inhouse/nordea.png'),
    offerUrl: '../../../nordea_offer.pdf',
    files: [],
    sv: {
      title: 'Bank',
      descriptionVeryShort: 'Här får du personlig rådgivare',
      descriptionShort: 'Här finner du bra stöd från en bank både som privatperson och företagare',
      descriptionLong: 'Som företagare är det bra med ekonomisk rådgivning ibland. Nordea har en unik avdelning och gör ingen skillnad på dig som privatperson eller företagare utan här har du en och samma person som hjälper dig på båda områden. Placeringsrådgivning, eller lån för både bolaget eller dig som privatperson.'
    },
    en: {
      title: 'Bank',
      descriptionVeryShort: 'Get a personal advisor',
      descriptionShort: 'Here you find a bank who supports you both in private and company errands',
      descriptionLong: 'As an entrepreneur, it is good to get financial advice sometimes. Nordea has a unique department and see no difference between you as an individual or an entrepreneur, with us it is the one and the same person who helps you in both areas. Investment advice, or loans for both the company or you as an individual.'
    }
  },
  {
    companyName: 'Qred',
    logoFilename: require('@/assets/img/offers/logos/qred.png'),
    offerCode: '',
    srcInhouse: require('@/assets/img/offers/inhouse/learning.png'),
    offerUrl: 'https://www.qred.se/partner-kreditkort/gigport',
    files: [],
    sv: {
      title: 'Finans',
      descriptionVeryShort: 'Visakort med cashback 1%',
      descriptionShort: 'Här får ni ett Visa kort utan månadsavgift med 1% cash back på allt ni köper. Fakturaköp för dig med lång betalningstid, pengar på kontot på 24h.',
      descriptionLong: 'Här får ni ett Visa kort utan månadsavgift med 1% cash back på allt ni köper. Fakturaköp för dig med lång betalningstid, pengar på kontot på 24h.'
    },
    en: {
      title: 'Finance',
      descriptionVeryShort: 'Visa card with cashback 1%',
      descriptionShort: 'Here you get a Visa card with no monthly fee and 1% cash back on everything you buy. Invoice purchase for you with a long payment period, money in your account in 24 hours.',
      descriptionLong: 'Here you get a Visa card with no monthly fee and 1% cash back on everything you buy. Invoice purchase for you with a long payment period, money in your account in 24 hours.'
    }
  },
  {
    companyName: 'Avanza',
    logoFilename: require('@/assets/img/offers/logos/avanza.png'),
    offerCode: '',
    srcInhouse: require('@/assets/img/offers/inhouse/nordea.png'),
    offerUrl: 'https://www.avanza.se/foretag/tjanstepension.html',
    files: [],
    sv: {
      title: 'Tjänstepension',
      descriptionVeryShort: 'Direktkontakt 08-409 421 30',
      descriptionShort: 'Kontakta Avanzas företagrådgivare direkt 08-409 421 30',
      descriptionLong: 'Att få tjänstepension som egenföretagare är väldigt enkelt och med Avanza blir det ännu enklare. Visste du att du kan avsätta upp till 35% av din årslön i tjänstepension och till och med betala lägre avgift än att ta ut lön? Kontakta Avanza för mer tips och råd.'
    },
    en: {
      title: 'Pension',
      descriptionVeryShort: 'Get in touch at 08-409 421 30',
      descriptionShort: 'Contact Avanza\'s business advisor directly 08-409 421 30',
      descriptionLong: 'Getting a pension as a self-employed person is very simple and with Avanza it will be even easier. Did you know that you can set aside up to 35% of your annual salary in occupational pension and even pay a lower fee than taking out a salary? Contact Avanza for more tips and advice.'
    }
  },
  {
    companyName: 'Trygg Hansa',
    logoFilename: require('@/assets/img/offers/logos/trygghansa.png'),
    offerCode: '',
    srcInhouse: require('@/assets/img/offers/inhouse/trygghansa.png'),
    offerUrl: 'https://www.trygghansa.se/tryggafirman?s_kwcid=AL!5973!3!380161233294!b!!g!!%2Btrygg%20%2Bhansa%20%2Btrygga%20%2Bfirman&gclsrc=aw.ds&ds_rl=1246724&gclid=CjwKCAjw1K75BRAEEiwAd41h1EAMiE7yQw_aesyoRmr8j13B53rX2Ps1fWPWEcsYdF47LNDAVOX3pRoCszcQAvD_BwE&gclsrc=aw.ds',
    files: [],
    sv: {
      title: 'Försäkring',
      descriptionVeryShort: 'Skaffa rätt försäkring',
      descriptionShort: 'Se till att du är rätt försäkrad, både för dig och för kundens skull',
      descriptionLong: 'I ditt företagande och som privatperson är det viktigt att du är rätt försäkrad. Med rätt betyder här inte för lite men heller inte för mycket. Trygg Hansa har lång erfarenhet av egenföretagare och deras försäkringsbehov, och sätter ihop ett paket som löser ditt specifika behov.'
    },
    en: {
      title: 'Insurance',
      descriptionVeryShort: 'Get your insurance here',
      descriptionShort: 'Insurance is an important part of being a consultant. Let experts help you to get the right setup',
      descriptionLong: 'In your business and as an individual, it is important that you are properly insured. Rightly so, it does not need to cost a little but also not too much. Trygg Hansa has extensive experience of self-employed people and their insurance needs, and puts together a package that solves your specific needs.'
    }
  },
  // {
  //   companyName: 'Insict',
  //   logoFilename: require('@/assets/img/offers/logos/insict.png'),
  //   srcInhouse: require('@/assets/img/offers/inhouse/doctor.png'),
  //   offerUrl: 'https://www.insict.nu/',
  //   files: [],
  //   sv: {
  //     title: 'Psykisk ohälsa',
  //     descriptionVeryShort: 'Logga in på Insict Direct som du hittar i menyn',
  //     descriptionShort: 'Här kan du få hjälp och information om psykisk ohälsa och beroende',
  //     descriptionLong: 'Här får ni tillgång till en vårdplattform där man kan få information eller hjälp med psykisk ohälsa och beroende inom spel, alkohol och droger.'
  //   },
  //   en: {
  //     title: 'Mental illness',
  //     descriptionVeryShort: 'Log in to Insict Direct which you will find in the menu',
  //     descriptionShort: 'Here you can get help and information about mental illness and addiction',
  //     descriptionLong: 'Here you get access to a health platform where you can get information or help with mental illness and addiction in gambling, alcohol and drugs.'
  //   }
  // },
  // {
  //   companyName: 'Vimentis',
  //   logoFilename: require('@/assets/img/offers/logos/vimentis.png'),
  //   offerCode: '',
  //   srcInhouse: require('@/assets/img/offers/inhouse/nordea.png'),
  //   offerUrl: 'https://www.vimentis.se/konsultkedjan/',
  //   files: [],
  //   sv: {
  //     title: 'Försäljning',
  //     descriptionVeryShort: 'Här får du hjälp med försäljning ur ett konsultperspektiv',
  //     descriptionShort: 'Här får du hjälp med försäljning ur ett konsultperspektiv',
  //     descriptionLong: 'I samarbete med Vimentis har vi tagit fram en utbildning som heter Försäljning ur ett konsultperspektiv. Utbildningen är live och online och här får ni möjligheten att lära er social selling, boka möte, story telling och mycket mer för att få kontroll på din försäljningsprocess.'
  //   },
  //   en: {
  //     title: 'Sales',
  //     descriptionVeryShort: 'Here you get help with sales from a consulting perspective',
  //     descriptionShort: 'Here you get help with sales from a consulting perspective',
  //     descriptionLong: 'In collaboration with Vimentis, we have developed a training called Sales from a consulting perspective. The training is live and online and here you get the opportunity to learn social selling, book a meeting, story telling and much more to get control of your sales process.'
  //   }
  // },
  {
    companyName: 'Learning Tree',
    logoFilename: require('@/assets/img/offers/logos/learning.png'),
    offerCode: '',
    srcInhouse: require('@/assets/img/offers/inhouse/learning.png'),
    offerUrl: 'https://www.learningtree.se/konsultkedjan/',
    files: [],
    sv: {
      title: 'Utbildning',
      descriptionVeryShort: '5 - 10% rabatt på alla utbildningar',
      descriptionShort: 'Din kompetens måste både utvecklas och underhållas!',
      descriptionLong: 'Du är ditt varumärke och det är din kunskap som är din ”corebusiness”. Det är viktigt att du fortbildar dig och utökar dina kunskaper, omvärlden står inte stilla i detta avseende. Learning Tree har en unik kompetens och erfarenhet av att leverera utbildningstjänster till både små och stora organisationer som leder till mätbara resultat.'
    },
    en: {
      title: 'Education',
      descriptionVeryShort: '5 - 10% off on all courses',
      descriptionShort: 'Your competence needs both updates and further progress',
      descriptionLong: 'You are your brand and it is your knowledge that is your "core business". It is important that you further your education and expand your knowledge, the outside world does not stand still in this regard. Learning Tree has a unique competence and experience of delivering educational services to both small and large organizations that lead to measurable results.'
    }
  },
  {
    companyName: 'Nordic Wellness',
    logoFilename: require('@/assets/img/offers/logos/nordic.png'),
    offerCode: '',
    srcInhouse: require('@/assets/img/offers/inhouse/nordic.png'),
    offerUrl: '../../../nordic_offer.pdf',
    files: [],
    sv: {
      title: 'Friskvård',
      descriptionVeryShort: '2995 SEK första året!',
      descriptionShort: 'Din kropp måste vara i form för att prestera, underhåll den!',
      descriptionLong: 'Som konsult säljer du dina timmar. Det är viktigt att du har en god hälsa och inte sjuk för ofta. God hälsa skapar man genom ett proaktivt tränande. Nordic Wellnes erbjuder våra konsulter ett första års medlemskap till ett mycket bra pris. Detta gäller för alla Nordic Wellnes anläggningar i hela Sverige, så du kan träna även när du är på resande fot eller har uppdrag någon annanstans än där du bor.'
    },
    en: {
      title: 'Health Benefits',
      descriptionVeryShort: '2995 SEK for the first year!',
      descriptionShort: 'Your body and brain is your instrument, please keep it in shape',
      descriptionLong: 'As a consultant, you sell your hours. It is important that you have good health and not get sick too often. Good health is created through proactive training. Nordic Wellnes offers our consultants a first year membership at a very good price. This applies to all Nordic Wellnes facilities throughout Sweden, so you can train even when you are traveling or have assignments elsewhere than where you live.'
    }
  },
  // {
  //   companyName: 'Scandic',
  //   logoFilename: require('@/assets/img/offers/logos/scandic.png'),
  //   offerCode: 'D000045971',
  //   srcInhouse: require('@/assets/img/offers/inhouse/scandic.png'),
  //   offerUrl: 'https://www.scandichotels.se/',
  //   files: [],
  //   sv: {
  //     title: 'Hotell',
  //     descriptionVeryShort: '5% rabatt på alla övernattningar',
  //     descriptionShort: 'Få rabatter på alla dina övernattningar',
  //     descriptionLong: 'Som ansluten konsult till GigPort-plattformen, erbjuder Scandic dig en rabatt på 5 % på alla dina övernattningar, både i tjänsten och privat.'
  //   },
  //   en: {
  //     title: 'Hotel',
  //     descriptionVeryShort: '5% off on all your stays',
  //     descriptionShort: 'Get a discount on your hotel stays',
  //     descriptionLong: 'As a signed up contractor to GigPort, Scandic offers you a 5% discount on all your overnight stays, both for work and privately.'
  //   }
  // },
  {
    companyName: 'Smarteyes',
    logoFilename: require('@/assets/img/offers/logos/smarteyes.png'),
    offerCode: '',
    srcInhouse: require('@/assets/img/offers/inhouse/smarteyes.png'),
    offerUrl: '',
    files: [
      {
        src: '../../../smarteyes_rekvistitionsblankett.pdf',
        sv: {
          button: 'Skriv ut & ta med till butik',
          text: '1. Skriv ut & ta med rekvisitionen till butik'
        },
        en: {
          button: 'Print & bring to store',
          text: '1. Print the requisition & bring it to a store'
        }
      },
      {
        src: '../../../smarteyes_prislista.pdf',
        sv: {
          button: 'Till erbjudandet',
          text: '2. Se prislistan här nedan'
        },
        en: {
          button: 'To the offer',
          text: '2. See price list below'
        }
      }
    ],
    sv: {
      title: 'Glasögon',
      descriptionVeryShort: 'Gratis synundersökning och bra rabatter',
      descriptionShort: 'Få bra rabatter på glasögon',
      descriptionLong: 'Du kan ta del av konsultkedjans avtal med Smarteyes som ger dig ett mycket bra erbjudande på terminalglasögon. Behöver du även glasögan privat, får du 20% rabatt på dessa.'
    },
    en: {
      title: 'Glasses',
      descriptionVeryShort: 'Free eye examination and discounts',
      descriptionShort: 'Get good discounts on your glasses',
      descriptionLong: 'You can take advantage of GigPort\'s agreement with Smarteyes, which gives you a very good offer on terminal glasses. If you also need glasses privately, you get a 20% discount on these.'
    }
  },
  {
    companyName: 'Intensive Code',
    logoFilename: require('@/assets/img/offers/logos/intensive-code-color.png'),
    offerCode: '',
    srcInhouse: require('@/assets/img/offers/inhouse/trygghansa.png'),
    offerUrl: 'https://www.intensivecode.se/',
    files: [],
    sv: {
      title: 'Bli en programmerare',
      descriptionVeryShort: 'Bli en programmerare på 6 månader',
      descriptionShort: '',
      descriptionLong: 'Det saknas 72 000 systemutvecklare på den svenska arbetsmarknaden. Ta chansen att utbilda dig helt från grunden och få jobb som systemutvecklare på endast 6 månader! Utbildningen hålls i Helsingborg med två fysiska träffar i veckan på kvällstid. Det innebär att du kan kombinera utbildningen med ditt arbete och behålla din ekonomiska trygghet.'
    },
    en: {
      title: 'Become a programmer',
      descriptionVeryShort: 'Become a programmer in 6 months',
      descriptionShort: '',
      descriptionLong: 'There is a demand for over 72,000 developers on the Swedish labor market. Take the chance to train you completely from scratch and get jobs as system developers in only 6 months! The education is held in Helsingborg with two physical hits a week in the evening. This means that you can combine the education with your work and maintain your financial security.'
    }
  },
  {
    companyName: 'HELkom',
    logoFilename: require('@/assets/img/offers/logos/HELkom.png'),
    offerCode: '',
    srcInhouse: require('@/assets/img/offers/inhouse/trygghansa.png'),
    offerUrl: 'https://helkom.se/gigport/',
    files: [],
    sv: {
      title: 'Digital körjournal',
      descriptionVeryShort: 'Digital körjournal',
      descriptionShort: '',
      descriptionLong: 'Med en digital körjournal så kommer ni kunna spara både tid och funderingar på vilka körningar ni gjort. Det blir rätt från början! Allt finns tillgängligt i en app där ni smidigt kan justera resor mellan privat och tjänst för att få rätt underlag till bokföringen. En tjänst som skapar kontroll och sparar tid!'
    },
    en: {
      title: 'Digital Driving Journal',
      descriptionVeryShort: 'Digital Driving Journal',
      descriptionShort: '',
      descriptionLong: 'With a digital driving journal, you will be able to save both time and thoughts on which runs you made. It gets right from the start! Everything is available in an app where you can smoothly adjust trips between private and service to get the right basis for the accounting. A service that creates control and saves time!'
    }
  }
]

export default offers
