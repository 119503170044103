<template>
  <div>
    <v-container class="justify-center fill-height">
      <v-row justify="center">
        <v-col
          v-for="offer in offers"
          :key="offer.name"
          cols="12"
          md="10"
          sm="12"
        >
          <vue-aos animation-class="animate__animated animate__fadeInUp">
            <v-sheet
              :id="offer.en.title"
              class="pa-4 elevation-1 background fill-height rounded-xl"
              rounded
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <div :class="[$vuetify.breakpoint.smAndDown ? 'text-center' : '']">
                    <v-avatar
                      v-if="$vuetify.breakpoint.smAndDown"
                      class="ma-3 pa-3 white rounded"
                      width="150"
                      tile
                    >
                      <v-img
                        contain
                        :src="offer.logoFilename"
                      />
                    </v-avatar>
                  </div>
                  <v-card-title
                    class="text-h5 mb-3"
                    v-text="$i18n.locale === 'sv' ? offer.sv.title : offer.en.title"
                  />
                  <v-card-subtitle
                    class="text-body-1 mb-4"
                    v-text="$i18n.locale === 'sv' ? offer.sv.descriptionLong : offer.en.descriptionLong"
                  />
                  <v-card-text :class="[ $vuetify.breakpoint.smAndDown ? 'text-center' : 'dark--text']">
                    <v-btn
                      light
                      rounded
                      @click="openOffer(offer)"
                      v-text="$t('Pages.Offers.take_part')"
                    />
                  </v-card-text>
                </div>
                <v-divider
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="mx-4"
                  inset
                  vertical
                />
                <div class="align-center justify-center d-flex">
                  <v-avatar
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="ma-3 pa-3 white rounded"
                    size="150"
                    tile
                  >
                    <v-img
                      contain
                      :src="offer.logoFilename"
                    />
                  </v-avatar>
                </div>
              </div>
            </v-sheet>
          </vue-aos>
        </v-col>
        <v-col
          md="10"
          sm="12"
          class="justify-center align-center d-flex py-16"
        >
          {{ $t('Pages.Offers.missing_offer') }}
          <a
            class="ml-2"
            href="mailto:info@gigport.se"
          >info@gigport.se</a>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-if="offerDialogData"
      v-model="offerDialogUrl"
      max-width="340"
    >
      <v-card
        class="text-center rounded-xl neutral pa-4"
        rounded="xl"
      >
        <v-avatar
          class="ma-3 pa-3 white rounded"
          size="150"
          tile
        >
          <v-img
            contain
            :src="offerDialogData.logoFilename"
          />
        </v-avatar>
        <p
          class="text-h6"
          v-text="$i18n.locale === 'sv' ? offerDialogData.sv.descriptionVeryShort : offerDialogData.en.descriptionVeryShort"
        />
        <v-icon>file-download-outline</v-icon>
        <div
          v-if="offerDialogData.files.length > 0"
          class="mb-6"
        >
          <div
            v-for="(file, index) in offerDialogData.files"
            :key="file.id"
          >
            <div
              class="mb-2"
              v-text="$i18n.locale === 'sv' ? file.sv.text : file.en.text"
            />
            <v-btn
              :class="[{'ml-3': index !== 0}]"
              class="ml-2 mb-2"
              depressed
              large
              :href="file.src"
              target="_blank"
              light
              rounded
            >
              <span v-if="file.sv.button">{{ $i18n.locale === 'sv' ? file.sv.button : file.en.button }}</span>
            </v-btn>
          </div>
        </div>
        <v-card-actions
          v-if="offerDialogData.offerUrl"
          class="justify-center"
        >
          <v-btn
            x-large
            rounded
            depressed
            light
            :href="offerDialogData.offerUrl"
            target="_blank"
            v-text="$t('Pages.Offers.to_offer')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="offerDialogData"
      v-model="offerDialogCode"
      max-width="340"
    >
      <v-card
        class="text-center rounded-xl neutral pa-4"
        rounded="xl"
      >
        <v-avatar
          class="ma-3 pa-3 white rounded"
          size="150"
          tile
        >
          <v-img
            contain
            :src="offerDialogData.logoFilename"
          />
        </v-avatar>
        <p
          v-text="$i18n.locale === 'sv' ? offerDialogData.sv.descriptionVeryShort : offerDialogData.en.descriptionVeryShort"
        />
        <v-sheet
          rounded="xl"
          class="pa-4 mb-4 blue-grey darken-2"
        >
          <div
            ref="textToCopy"
            class="text-h5"
            v-text="offerDialogData.offerCode"
          />
        </v-sheet>
        <v-card-actions class="justify-center">
          <v-btn
            x-large
            rounded
            depressed
            light
            @click="copyAndContinue()"
            v-text="$t('Pages.Offers.copy_and')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="registerDialog"
      max-width="340"
    >
      <v-card
        class="text-center rounded-xl neutral pa-4"
        rounded="xl"
      >
        <v-card-text>
          Login or register to continue
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            x-large
            rounded
            depressed
            light
            @click="copyAndContinue()"
            v-text="$t('Pages.Offers.copy_and')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueAos from 'vue-aos'

export default {
  name: 'OffersList',
  components: {
    VueAos
  },
  props: {
    offers: {
      type: Array,
      default: () => []
    },
    createAlert: {
      type: Function,
      default: function () {}
    }
  },
  data () {
    return {
      registerDialog: false,
      offerDialogUrl: false,
      offerDialogCode: false,
      offerDialogData: null
    }
  },
  computed: {
    ...mapState(['isAuthenticated'])
  },
  methods: {
    copyAndContinue () {
      navigator.clipboard.writeText(this.offerDialogData.offerCode)
      setTimeout(() => {
        window.open(this.offerDialogData.offerUrl)
      })
    },
    openOffer (offer) {
      if (this.isAuthenticated) {
        this.offerDialogData = offer
        if (offer.offerCode) {
          this.offerDialogCode = true
        }
        if ((offer.offerUrl || offer.files) && !offer.offerCode) {
          this.offerDialogUrl = true
        }
      } else {
        this.createAlert(this.$t('Pages.Offers.login_to'), 'info', 'info')
        this.$router.push({ name: 'accounts-login', query: { redirect: 'offers' } })
      }
    }
  }
}
</script>

<style scoped>

</style>
